import { useEffect } from "react";
import WOW from "wowjs";
import "./App.scss";

import Home from './components/Home';
import Header from './components/Header';

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div id='main-wrapper'>
          <Header></Header>

          <div id='content' role='main'>
            <Home/>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;

import React from 'react';
import Typewriter from 'typewriter-effect';
import { Tooltip } from './Tooltip';

const Home = () => {
  return (
    <section
      id='home'
      className='bg-primary d-flex fullscreen position-relative py-5'
    >
      <div className='container my-auto py-4'>
        <div className='row'>
          <div className='col-lg-7 text-center text-lg-start align-self-center order-1 order-lg-0 wow fadeIn'>
            <h1 className='text-12 fw-300 mb-0 text-uppercase'>
              Hi, I'm a Software
            </h1>
            <h2 className='text-21 fw-600 text-uppercase mb-0 ms-n1'>
              <Typewriter
                options={{
                  strings: ['Engineer', 'Architect', 'Enthusiast'],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h2>
            <p className='text-5'>based in São Paulo, BRA.</p>
            <ul className='social-icons social-icons-lg social-icons-dark justify-content-center justify-content-lg-start mt-5'>
              <li className='social-icons-linkedin'>
                <Tooltip text='Linked In' placement='top'>
                  <a
                    href='https://www.linkedin.com/in/davialves/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='fab fa-linkedin' />
                  </a>
                </Tooltip>
              </li>
              <li className='social-icons-github'>
                <Tooltip text='Github' placement='top'>
                  <a
                    href='https://github.com/davi-alves'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='fab fa-github' />
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
          <div className='col-lg-5 text-center align-self-center mb-4 mb-lg-0 order-0 order-lg-1'>
            <div className='bg-light rounded-pill d-inline-block p-3 shadow-lg wow zoomIn'>
              <Tooltip text="Hi, I'm Davi Alves" placement='top'>
                <img
                  className='img-fluid rounded-pill d-block'
                  src='images/me.jpeg'
                  title="I'm Davi Alves"
                  alt="I'm Davi Alves"
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;

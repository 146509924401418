import React from 'react';

const Header = () => {
  return (
    <header id='header' className='sticky-top'>
      {/* Navbar */}
      <nav
        className={
          'primary-menu navbar navbar-expand-none navbar-overlay  bg-transparent border-bottom-0 text-5 fw-600 navbar-overlay-dark'
        }
      >
        <div className='container'>
          {/* Logo */}
          <div className='logo mt-3'>
            <img src='images/logo.svg' alt='Davi Akves' />
          </div>
          {/* Logo End */}
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default Header;
